<template>
  <div class="pageBox">
    <div class="containerBox">
      <div class="searBox" @click="goSquareSear()">
        <img src="../../../image/quanz/s.png" alt="" />请输入成员昵称
      </div>
       
      <div class="followingBox">
        <div class="hear_name" v-for="item in followMeList" :key="item.id">
          <img :src="item.followHeadImg" alt="" />
          <div>{{ item.notes }}</div>
        </div>
        <div class="hear_name" @click="gofollowPage()">
          <img src="../../../image/quanz/qbgz.png" alt="" />
          <div>全部关注</div>
        </div>
      </div>
       <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
          <van-list
            v-model="upperLoading"
            :finished="finished"
            :immediate-check="false"
            finished-text="没有更多了"
            offset="800"
            @load="onLoad"
          >
      <div class="conetnt_botm">
        <div class="contentBox" v-for="item in collectList" :key="item.id">
          <div class="content_top">
            <div class="content_hear" @click="gotrends(item.userId)">
              <img :src="item.userHeadImg" alt="" />
            </div>
            <div class="content_name_school">
              <div class="content_name">{{ item.userNickName }}</div>
              <div class="content_school">{{ item.schoolName }}</div>
            </div>
            <div class="content_topBtn" @click="showPopup(item)">
              <img src="../../../image/quanz/ygz.png" alt="" />
            </div>
          </div>
          <div class="content_abstract" @click="goTrendsDetail(item.id)">
            {{ item.content }}
          </div>
          <div class="content_img">
            <img :src="i" alt="" v-for="i in JSON.parse(item.imgs)" :key="i" />
          </div>
          <div class="content_footer">
            <div class="content_numBox">
              <div class="content_leftBox">
                <img src="../../../image/quanz/fx.png" alt="" />分享
              </div>
              <div class="content_leftBox">
                <img src="../../../image/quanz/pl.png" alt="" />{{
                  item.commentNum
                }}
              </div>
              <div class="content_leftBox">
                <img src="../../../image/quanz/dz.png" alt="" v-if="item.isLike === 0"/>
                <img src="../../../image/quanz/dz1.png" alt="" v-if="item.isLike === 1"/>
                {{item.likeNum}}
              </div>
            </div>
            <div class="content_del">
              <img src="../../../image/quanz/del.png" alt="" />
            </div>
          </div>
        </div>
      </div>
        </van-list>
        </van-pull-refresh>
    </div>
    <van-popup v-model="show" round position="bottom">
      <div class="popupDiv2" @click="lhEveent()">不再关注</div>
      <div class="line"></div>
      <div class="popupDiv1" @click="show = false">取消</div>
    </van-popup>
  </div>
</template>

<script>
import { followMe, myCollect, followCancel } from "../../../api/circle";
export default {
  data() {
    return {
      show: false,
      followMeList: [],
      collectList: [],
       search: {
        nickName: null,
        pageNum: 1,
        pageSize: 10,
      },
      // isLoading: false,
      // loading: false, //上拉加载
      // finished: false, //加载与不加载的状态
      total: 0,
      upperLoading: false, //上拉加载
      finished: true, //加载与不加载的状态
      listTwo: [], //用于上拉加载时将数据合并
      isLoading: false //下拉加载状态
    };
  },
  mounted() {
    followMe({
      pageNum: 1,
      pageSize: 10,
    }).then((res) => {
      console.log(res, "关注");
      this.followMeList = res.data.data.items;
    });
    this.getmyCollect();
  },
  methods: {
    getmyCollect() {
      this.search.pageNum = 1
      myCollect(this.search).then((res) => {
        console.log(res, "关注动态");
        // this.collectList = res.data.data.items;
        // this.collectList = this.collectList.concat(res.data.data.items);
        // if (this.collectList.length >= res.data.data.total) {
        //   this.finished = true;
        // } else {
        //   this.finished = false;
        // }
         if(res.data.code === 0){
          this.collectList = res.data.data.items;
          this.total = res.data.data.total;
          var num = this.total;
          this.total = num - 0;
           if (this.collectList.length >= this.total) {
            //将返回数据长度与总条数进行比较控制加载不加载的状态
            this.finished = true;
          } else {
            this.finished = false;
          }
        }
      });
    },
    //  onRefresh() {
    //   setTimeout(() => {
    //     this.search.pageNum = 1;
    //     this.finished = false;
    //     this.isLoading = false;
    //     this.loading = false;
    //     this.collectList = [];
    //     this.getmyCollect();
    //     this.$toast("刷新成功");
    //   }, 1000);
    // },
    //  onLoad() {
    //   this.search.pageNum ++;
    //   this.getmyCollect();
    // },
       // 下拉加载
    onRefresh() {
      setTimeout(() => {
        this.$toast("刷新成功");
        this.isLoading = false;
        this.getmyCollect();
      }, 1000);
    },
      // 上拉加载
    onLoad() {
      this.search.pageNum++; //加载时页码递增
      infoPage(this.search).then(res => {
        if (res.data.code === 0) {
          this.listTwo = res.data.data.items; //将接口返回赋值data定义的数组
          this.total = res.data.data.total - 0; //将接口返回的总数据条数赋值data中定义的total
          this.listTwo.forEach(item => {
            this.collectList.push(item);
            this.upperLoading = false;
            // this.finished = false;
          });
          // 加载状态结束 状态更新为false
          this.upperLoading = false;
          if (this.collectList.length >= this.total) {
            //将返回数据长度与总条数进行比较控制加载不加载的状态
            this.finished = true;
          }
        }
      });
    },
    // 搜索
    goSquareSear() {
      this.$router.push({ path: "/squareSear",query:{status:2} });
    },
    // 跳转详情
    goTrendsDetail(id) {
      this.$router.push({ path: "/trendsDetail", query: { id: id } });
    },
    // 关注列表
    gofollowPage() {
      this.$router.push({ path: "/followPage" });
    },
    // 跳转动态
    gotrends(userId) {
      this.$router.push({ path: "/TrendIndex" ,query:{status:1}});
    },
    showPopup(item) {
      console.log(item);
      this.cancel = item;
      this.show = true;
    },
    // 取消关注
    lhEveent() {
      followCancel(this.cancel.userId).then((res) => {
        console.log(res, "取消关注");
        if (res.data.code === 0) {
          this.getmyCollect();
        }
      });
    },
  },
};
</script>

<style scoped>
.containerBox {
  background: #f6f8fa;
  overflow: hidden;
  min-height: 100vh;
  /* padding-bottom: 120px; */
}
.conetnt_botm {
  margin-bottom: 40px;
}
.searBox {
  width: 686px;
  height: 72px;
  background: #ffffff;
  border-radius: 16px 16px 16px 16px;
  opacity: 1;
  margin-left: 32px;
  margin-top: 132px;
  font-size: 24px;
  font-weight: 400;
  color: #97a4b4;
  display: flex;
  align-items: center;
}
.searBox img {
  width: 32px;
  height: 32px;
  margin-left: 24px;
  margin-right: 12px;
}
.followingBox {
  display: flex;
  align-items: center;
  overflow-x: auto;
  padding-right: 32px;
  box-sizing: border-box;
  scroll-behavior: none;
}
.followingBox::-webkit-scrollbar {
  width: 0;
  height: 0;
  background-color: #fff;
  display: none;
}
.hear_name {
  text-align: center;
  font-size: 24px;
  font-weight: 400;
  color: #0b1526;
  margin-left: 32px;
}
.hear_name img {
  width: 132px;
  height: 132px;
  border-radius: 50%;
}
.followingBox {
  width: 686px;
  height: 246px;
  background: #ffffff;
  border-radius: 16px 16px 16px 16px;
  opacity: 1;
  margin-left: 32px;
  margin-top: 32px;
}
.contentBox {
  width: 686px;
  /* height: 636px; */
  background: #ffffff;
  border-radius: 16px 16px 16px 16px;
  opacity: 1;
  margin-left: 32px;
  margin-top: 32px;
  overflow: hidden;
}
.content_top {
  display: flex;
  align-items: center;
  margin-top: 36px;
}
.content_hear {
  width: 80px;
  height: 80px;
  margin-left: 32px;
}
.content_hear img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}
.content_name_school {
  width: 380px;
  margin-left: 32px;
}
.content_name {
  font-size: 28px;
  font-weight: 600;
  color: #0b1526;
}
.content_school {
  font-size: 24px;
  font-weight: 400;
  color: #97a4b4;
  margin-top: 6px;
}

.content_topBtn img {
  width: 120px;
  height: 56px;
}
.content_abstract {
  width: 622px;
  font-size: 32px;
  font-weight: 400;
  color: #0b1526;
  margin-left: 32px;
  margin-top: 32px;
  line-height: 48px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}
.content_img {
  /* display: flex; */
  margin-left: 32px;
  margin-top: 32px;
}
.content_img img {
  width: 198px;
  height: 198px;
  border-radius: 8px 8px 8px 8px;
  margin-right: 12px;
}
.content_footer {
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 32px;
}
.content_numBox {
  /* width: 400px; */
  display: flex;
  align-items: center;
  justify-content: space-around;
  /* margin-left: 32px; */
  margin-top: 12px;
}
.content_leftBox {
  font-size: 24px;
  font-weight: 400;
  color: #97a4b4;
  display: flex;
  align-items: center;
}
.content_leftBox img {
  width: 48px;
  height: 48px;
  margin-right: 16px;
  margin-left: 32px;
}
.content_del img {
  width: 24px;
  height: 24px;
  margin-right: 32px;
  display: flex;
  margin-top: 12px;
}
.popupDiv1,
.popupDiv2 {
  width: 750px;
  height: 104px;
  background: #ffffff;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  font-size: 32px;
  font-weight: 400;
  color: #0b1526;
  text-align: center;
  line-height: 104px;
  border-bottom: 1px solid #f6f8fa;
}
.popupDiv2 {
  color: #fa2819;
}
.line {
  width: 750px;
  height: 20px;
  background: #f6f8fa;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
}
</style>
