<template>
  <div class="pageBox">
    <div class="topBox">
      <div class="topLeftNav">
        <div
          v-for="item in navList"
          :key="item.id"
          :class="item.id === $store.state.circleActive ? 'activestyle' : ''"
          @click="navEvent(item)"
        >
          {{ item.name }}
        </div>
      </div>
      <div class="topRight" @click="goSend()">
        <img src="../../image/quanz/x.png" alt="" />发表动态
      </div>
    </div>
    <squarePage v-if="this.$store.state.circleActive === 0"></squarePage>
    <following v-if="this.$store.state.circleActive === 1"></following>
    <dialogue v-if="this.$store.state.circleActive === 2"></dialogue>
    <van-overlay :show="show" @click="show = false">
      <img
        src="../../image/quanz/qzrz.png"
        alt=""
        class="rzImg"
        @click="goauthentication()"
      />
    </van-overlay>
  </div>
</template>

<script>
import squarePage from "./child/square.vue";
import following from "./child/following.vue";
import dialogue from "./child/dialogue.vue";
import Cookies from "js-cookie";
export default {
  components: {
    squarePage,
    following,
    dialogue,
  },
  data() {
    return {
      show: false,
      navList: [
        {
          id: 0,
          name: "广场",
        },
        {
          id: 1,
          name: "我的关注",
        },
        {
          id: 2,
          name: "对话",
        },
      ],
      navActive: 0,
    };
  },
  mounted() {
    if (Cookies.get("token")) {
      console.log(1111);
      const user = this.$store.state.user;
      console.log(user,'****');
      if (user.circleAuth === 1 || user.circleAuth === 0) {
        this.show = true;
      } else if(user.circleAuth === 2){
        this.show = false;
      }
    } else {
      this.$router.push({ path: "/login" });
    }
  },
  methods: {
    navEvent(item) {
      this.navActive = item.id;
      console.log(this.navActive);
      this.$store.commit("circleActive", item.id);
    },
    goSend() {
      console.log(11);
      this.$router.push({ path: "/sendPage" });
    },
    goauthentication() {
      this.$router.push({ path: "/authentication" });
    },
  },
};
</script>

<style scoped>
.topBox {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  background-color: #fff;
  top: 0px;
  z-index: 9999;
}

.topLeftNav {
  display: flex;
  align-items: center;
  font-size: 28px;
  font-weight: 400;
  color: #97a4b4;
}

.topLeftNav div {
  margin-left: 32px;
  margin-right: 16px;
}

.activestyle {
  font-size: 36px;
  font-weight: 600;
  color: #0b1526;
}

.topRight {
  width: 172px;
  height: 56px;
  border-radius: 36px 36px 36px 36px;
  opacity: 1;
  border: 2px solid #f6f8fa;
  margin-right: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: 400;
  color: #0b1526;
}

.topRight img {
  width: 24px;
  height: 24px;
  margin-right: 6px;
}

.containerBox {
  background: #f6f8fa;
  overflow: hidden;
  min-height: 100vh;
  /* padding-bottom: 120px; */
}

.conetnt_botm {
  margin-bottom: 140px;
}

.searBox {
  width: 686px;
  height: 72px;
  background: #ffffff;
  border-radius: 16px 16px 16px 16px;
  opacity: 1;
  margin-left: 32px;
  margin-top: 132px;
  font-size: 24px;
  font-weight: 400;
  color: #97a4b4;
  display: flex;
  align-items: center;
}

.searBox img {
  width: 32px;
  height: 32px;
  margin-left: 24px;
  margin-right: 12px;
}

.contentBox {
  width: 686px;
  height: 636px;
  background: #ffffff;
  border-radius: 16px 16px 16px 16px;
  opacity: 1;
  margin-left: 32px;
  margin-top: 32px;
  overflow: hidden;
}

.content_top {
  display: flex;
  margin-top: 36px;
}

.content_hear {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: red;
  margin-left: 32px;
}

.content_name_school {
  width: 380px;
  margin-left: 32px;
}

.content_name {
  font-size: 28px;
  font-weight: 600;
  color: #0b1526;
}

.content_school {
  font-size: 24px;
  font-weight: 400;
  color: #97a4b4;
  margin-top: 6px;
}

.content_topBtn img {
  width: 120px;
  height: 56px;
}

.content_abstract {
  width: 622px;
  font-size: 32px;
  font-weight: 400;
  color: #0b1526;
  margin-left: 32px;
  margin-top: 32px;
  line-height: 48px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}

.content_img {
  /* display: flex; */
  margin-left: 32px;
  margin-top: 32px;
}

.content_img img {
  width: 198px;
  height: 198px;
  border-radius: 8px 8px 8px 8px;
  margin-right: 12px;
}

.content_footer {
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content_numBox {
  /* width: 400px; */
  display: flex;
  align-items: center;
  justify-content: space-around;
  /* margin-left: 32px; */
  margin-top: 12px;
}

.content_leftBox {
  font-size: 24px;
  font-weight: 400;
  color: #97a4b4;
  display: flex;
  align-items: center;
}

.content_leftBox img {
  width: 48px;
  height: 48px;
  margin-right: 16px;
  margin-left: 32px;
}

.content_del img {
  width: 24px;
  height: 24px;
  margin-right: 32px;
  display: flex;
  margin-top: 12px;
}

.rzImg {
  width: 600px;
  height: 710px;
  margin-top: 388px;
  margin-left: 73px;
}
</style>
