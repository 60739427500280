<template>
  <div class="pageBox">
    <div class="containerBox">
      <div class="searBox">
        <img src="../../../image/quanz/s.png" alt="" /><input
          type="text"
          placeholder="请输入成员昵称" v-model="name"
        />
      </div>
      <div class="likeCommentCollect">
        <div class="likeBox" @click="golikeCollection()">
          <img src="../../../image/quanz/zs.png" alt="" />
          <div>赞和收藏</div>
        </div>
        <div class="likeBox" @click="goCommentPage()">
          <img src="../../../image/quanz/dhPl.png" alt="" />
          <div>评论</div>
        </div>
        <div class="likeBox" @click="goFansPage()">
          <img src="../../../image/quanz/fsi.png" alt="" />
          <div>粉丝</div>
        </div>
      </div>
      <div class="line"></div>
      <div class="addressBook">
        <div class="address" @click="goAddressPage()">
          <img src="../../../image/quanz/txl.png" alt="" />
          <div>通讯录</div>
        </div>
      </div>
      <div class="bookContent" v-for="item in conversations" :key="item.conversationID" @click="gochatPage(item.userID)">
        <div class="contetnHearName">
          <div class="contentHear">
            <img :src="item.headImg">
          </div>
          <div class="yuan" v-if="item.unreadCount > 0">{{ item.unreadCount }}</div>
          <div class="contentRight">
            <div class="nameTime">
              <div class="nameStylle" v-if="item.remark == ''"> {{ item.nick }} <span v-if="item.remark === ''">非好友</span></div>
              <div class="nameStylle" v-if="item.remark != ''"> {{ item.remark }} <span v-if="item.remark === ''">非好友</span></div>
              <div class="timeStyle">{{ item.lastTime }}</div>
            </div>
            <div class="contetnStylle" v-if="item.msgType === 'TIMTextElem'">{{ item.lastMsg }}</div>
<!--            <div class="contetnStylle" v-if="item.msgType == this.Tim.TYPES.MSG_IMAGE">-->
<!--              <van-tab v-for="img in item.lastMessage.payload.imageInfoArray" :key="img.uuid">-->
<!--                <img :src="img.url">-->
<!--              </van-tab>-->
<!--            </div>-->
<!--            <div class="contetnStylle" v-if="item.msgType == this.Tim.TYPES.MSG_AUDIO">{{ item.lastMessage.payload.url }}</div>-->
<!--            <div class="contetnStylle" v-if="item.msgType == this.Tim.TYPES.MSG_VIDEO">{{ item.lastMessage.payload.videoUrl }}</div>-->
          </div>
        </div>
      </div>
<!--      </van-tab>-->
    </div>
  </div>
</template>

<script>
import {getMsgSendTime} from "@/utils/CommonUtils"
let onConversationListUpdated;
export default {
  data() {
    return {
      conversations: [],
      isSyncCompleted: false,
      name: '',
    };
  },
  mounted() {
    let user = this.$store.state.user;
    let tis = this;
    if (user.circleAuth == 2) {
      // SDK 进入 ready 状态时触发，接入侧监听此事件，然后可调用 SDK 发送消息等 API，使用 SDK 的各项功能。
      // let onSdkReady = function(event) {
      //   console.log("onSdkReady", event)
      //   this.getConversations();
      //   // this.sendTextMsg();
      // };
      // this.tim.on(this.Tim.EVENT.SDK_READY, onSdkReady, this);
      //SDK 进入 not ready 状态时触发，此时接入侧将无法使用 SDK 发送消息等功能。如果想恢复使用，接入侧需调用 login 接口，驱动 SDK 进入 ready 状态。
      // let onSdkNotReady = function(event) {
      //   console.log("onSdkNotReady",event)
      //   // this.imLogin()
      // };
      // this.tim.on(this.Tim.EVENT.SDK_NOT_READY, onSdkNotReady, this);
      onConversationListUpdated = function(event) {
        // console.log("onConversationListUpdated", event)
        let conversationMap = new Map();
        for (let index in event.data) {
          conversationMap.set(event.data[index].conversationID, event.data[index])
        }
        if (tis.conversations.length < 1) {
          for (const val of conversationMap.values()) {
            let oneMsg = {};
            oneMsg.conversationID = val.conversationID;
            oneMsg.userID=val.userProfile.userID;
            oneMsg.nick=val.userProfile.nick;
            oneMsg.headImg=val.userProfile.avatar;
            oneMsg.remark= val.remark;
            oneMsg.lastMsg= val.lastMessage.messageForShow;
            oneMsg.lastTime= getMsgSendTime(val.lastMessage.lastTime);
            oneMsg.msgType= val.lastMessage.type;
            oneMsg.unreadCount= val.unreadCount;
            tis.conversations.push(oneMsg);
          }
        } else {
          let newConversations = [];
          for (const val of tis.conversations) {
            if (conversationMap.get(val.conversationID) == 'undefind') {
              let notMsg = conversationMap.get(val.conversationID)
              let oneMsg = {};
              oneMsg.conversationID = notMsg.conversationID;
              oneMsg.userID=notMsg.userProfile.userID;
              oneMsg.nick=notMsg.userProfile.nick;
              oneMsg.headImg=notMsg.userProfile.avatar;
              oneMsg.remark= notMsg.remark;
              oneMsg.lastMsg= notMsg.lastMessage.messageForShow;
              oneMsg.lastTime= getMsgSendTime(notMsg.lastMessage.lastTime);
              oneMsg.msgType= notMsg.lastMessage.type;
              oneMsg.unreadCount= notMsg.unreadCount;
              newConversations.push(oneMsg);
            } else {
              let oneMsg = conversationMap.get(val.conversationID);
              val.conversationID = oneMsg.conversationID
              val.userID=oneMsg.userProfile.userID;
              val.nick=oneMsg.userProfile.nick;
              val.headImg=oneMsg.userProfile.avatar;
              val.remark= oneMsg.remark;
              val.lastMsg= oneMsg.lastMessage.messageForShow;
              val.lastTime= getMsgSendTime(oneMsg.lastMessage.lastTime);
              val.msgType= oneMsg.lastMessage.type;
              val.unreadCount= oneMsg.unreadCount;
              newConversations.push(val);
            }
          }
          tis.conversations = newConversations;
        }
      };
      this.tim.on(this.Tim.EVENT.CONVERSATION_LIST_UPDATED, onConversationListUpdated);

      if (this.tim.isReady()) {
        this.getConversations();
      }
    }
  },
  destroyed() {
    this.tim.off(this.Tim.EVENT.CONVERSATION_LIST_UPDATED, onConversationListUpdated);
  },
  methods: {
    // 跳转赞和收藏
    golikeCollection() {
      this.$router.push({ path: "/likeCollection" });
    },
    // 跳转评论
    goCommentPage() {
      this.$router.push({ path: "/circleCommentPage" });
    },
    // 跳转我的粉丝
    goFansPage() {
      this.$router.push({ path: "/fansPage" });
    },
    // 跳转通讯录
    goAddressPage() {
      this.$router.push({ path: "/addressPage" });
    },
    // 聊天
    gochatPage(userId) {
      this.$router.push({ path: "/chatPage", query:{"userId": userId } });
    },
    // 登录im
    // imLogin() {
    //   let user = this.$store.state.user;
    //   if (user.circleAuth == 2 && !this.tim.isReady()) {
    //     let loginPromise = this.tim.login({userID: user.id + "", userSig: user.imSig});
    //     loginPromise.then(function (imResponse) {
    //       if (imResponse.data.repeatLogin === true) {
    //         // 标识账号已登录，本次登录操作为重复登录。
    //         console.log(imResponse.data.errorInfo);
    //       }
    //     }).catch(function (imError) {
    //       console.warn('login error:', imError); // 登录失败的相关信息
    //     });
    //   }
    // },
    getConversations() {
      // 获取全量的会话列表
      let promise = this.tim.getConversationList();
      let tis = this;
      promise.then(function(imResponse) {
        // console.log("getConversationList", imResponse)
        const conversationList = imResponse.data.conversationList; // 全量的会话列表，用该列表覆盖原有的会话列表
        const isSyncCompleted = imResponse.data.isSyncCompleted; // 从云端同步会话列表是否完成
        if (conversationList.length > 0) {
          for (let index in conversationList) {
            var val = conversationList[index];
            let oneMsg = {};
            oneMsg.conversationID = val.conversationID;
            oneMsg.userID=val.userProfile.userID;
            oneMsg.nick=val.userProfile.nick;
            oneMsg.headImg=val.userProfile.avatar;
            oneMsg.remark= val.remark;
            oneMsg.lastMsg= val.lastMessage.payload.text
            oneMsg.lastTime= getMsgSendTime(val.lastMessage.lastTime);
            oneMsg.msgType= val.lastMessage.type;
            oneMsg.unreadCount= val.unreadCount;
            tis.conversations.push(oneMsg);
          }
          if (!isSyncCompleted) {
            tis.getConversations();
          }
        }
      }).catch(function(imError) {
        console.warn('getConversationList error:', imError); // 获取会话列表失败的相关信息
      });
    },
    // 查询好友
    searchFriend() {
      let friends = this.conversations;
      for (let item in friends) {
        // if (item.)
      }
    },
    // 发送文本消息
    sendTextMsg() {
      // 发送文本消息，Web 端与小程序端相同
      // 1. 创建消息实例，接口返回的实例可以上屏
      let message = this.tim.createTextMessage({
        to: '12',
        conversationType: this.Tim.TYPES.CONV_C2C,
        // 消息优先级，用于群聊。如果某个群的消息超过了频率限制，后台会优先下发高优先级的消息
        // priority: TencentCloudChat.TYPES.MSG_PRIORITY_NORMAL,
        payload: {
          text: 'Hello world!'
        },
        // 如果您发消息需要已读回执，需购买旗舰版套餐，并且创建消息时将 needReadReceipt 设置为 true
        needReadReceipt: true
        // 消息自定义数据（云端保存，会发送到对端，程序卸载重装后还能拉取到）
        // cloudCustomData: 'your cloud custom data'
      });
      // 2. 发送消息
      let promise = this.tim.sendMessage(message);
      promise.then(function(imResponse) {
        // 发送成功
        console.log(imResponse);
      }).catch(function(imError) {
        // 发送失败
        console.warn('sendMessage error:', imError);
      });
    },
  },
};
</script>

<style scoped>
.containerBox {
  overflow: hidden;
  min-height: 100vh;
  /* padding-bottom: 120px; */
}
.searBox {
  width: 686px;
  height: 72px;
  background: #f6f8fa;
  border-radius: 16px 16px 16px 16px;
  opacity: 1;
  margin-left: 32px;
  margin-top: 132px;
  font-weight: 400;
  display: flex;
  align-items: center;
}
.searBox img {
  width: 32px;
  height: 32px;
  margin-left: 24px;
  margin-right: 12px;
}
.searBox input {
  width: 500px;
  border: none;
  font-size: 24px;
  color: #97a4b4;

  background: #f6f8fa;
}
.likeCommentCollect {
  display: flex;
  justify-content: space-around;
  margin-top: 32px;
}
.likeBox {
  font-size: 28px;
  font-weight: 400;
  color: #97a4b4;
  text-align: center;
}
.likeBox img {
  width: 120px;
  height: 120px;
}
.likeBox div {
  margin-top: 16px;
}
.line {
  width: 750px;
  height: 20px;
  background: #f6f8fa;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  margin-top: 32px;
}
.address {
  display: flex;
  align-items: center;
  margin-left: 28px;
  margin-top: 32px;
}
.address img {
  width: 104px;
  height: 104px;
}
.address div {
  font-size: 32px;
  font-weight: 600;
  color: #0b1526;
  margin-left: 20px;
}
.bookContent {
  width: 686px;
  height: 172px;
  border-top: 1px solid #f6f8fa;
  margin-top: 32px;
}
.contetnHearName {
  display: flex;
  margin-top: 36px;
}
.bookContent {
  margin-left: 28px;
}
.contentHear {
  width: 104px;
  height: 104px;
  //background: blue;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
}
.yuan {
  position: absolute;
  //top: 0px;
  //right: 0px;
  width: 28px;
  height: 28px;
  background: #fa2819;
  border-radius: 24px 24px 24px 24px;
  opacity: 1;
  font-size: 20px;
  font-weight: 600;
  color: #ffffff;
  text-align: center;
  line-height: 30px;
  left: 104px;
}
.contentHear img {
  height: 100%;
}
.nameTime {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 20px;
}
.nameStylle {
  width: 506px;
  font-size: 32px;
  font-weight: 400;
  color: #0b1526;
  display: flex;
  align-items: center;
}
.nameStylle span {
  display: inline-block;
  /* width: 76px; */
  /* height: 32px; */
  background: #0957c3;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  font-size: 20px;
  font-weight: 400;
  color: #ffffff;
  padding: 6px 8px 6px 8px;
  margin-left: 8px;
}
.timeStyle {
  font-size: 24px;
  font-weight: 400;
  color: #97a4b4;
}
.contetnStylle {
  width: 566px;
  font-size: 28px;
  font-weight: 400;
  color: #97a4b4;
  margin-left: 20px;
  margin-top: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
